<template>
  <div class="artist-container col-20 col-25-pc-small col-33-tablet col-50-sp">
    <div class="image-container">
      <div class="skeleton" v-show="!imageLoaded"></div>
      <img
        v-show="imageLoaded"
        class="bgimg"
        :src="require(`../assets/artists/${artist.href}`)"
        :style="`object-position: ${artist.imagePosition}`"
        :alt="artist.name"
        @load="onImgLoad"
      />
    </div>
    <h3 v-if="imageLoaded">{{ artist.name }}</h3>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {
    artist: { type: Object, default: () => {} },
  },
  setup: () => {
    const imageLoaded = ref(false);

    const onImgLoad = () => {
      imageLoaded.value = true;
    };

    return { imageLoaded, onImgLoad };
  },
});
</script>

<style lang="scss" scoped>
.artist-container {
  box-sizing: border-box;
  padding: 2rem;
  transition: opacity 0.3s;

  .image-container {
    position: relative;
    z-index: 0;
    width: 100%;
    line-height: 0;
    padding-bottom: 100%;

    .bgimg {
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      box-shadow: 2px 5px 15px rgb(165, 165, 165);
    }

    .skeleton {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      background: #e9ecef no-repeat;
      animation: pulse 2s infinite;
      animation-delay: 0.5s;
      border-radius: 50%;
    }

    @keyframes pulse {
      0% {
        opacity: 1;
      }

      50% {
        opacity: 0.4;
      }

      100% {
        opacity: 1;
      }
    }
  }

  &:hover {
    opacity: 0.7;
  }
}

@media screen and (max-width: 400px) {
  .artist-container {
    padding: 1rem;
  }
}
</style>
