<template>
  <div class="artist">
    <Header message="Artists" />
    <div class="grid-container">
      <Artist
        v-for="artist in data.artists"
        :key="artist.name"
        :artist="artist"
        @click="openArtistDrawer(artist)"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import store from "../store";
import data from "../data/data.json";

import Header from "../components/Header.vue";
import Artist from "../components/Artist.vue";

export default defineComponent({
  components: {
    Artist,
    Header,
  },
  setup: () => {
    const drawerIsVisible = ref(false);

    const openArtistDrawer = (artist) => {
      store.dispatch("SHOW_ARTIST", artist);
    };

    return {
      data,
      drawerIsVisible,
      openArtistDrawer,
    };
  },
});
</script>

<style lang="scss">
.grid-container {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;

  .artist-container {
    box-sizing: border-box;
    cursor: pointer;
  }
}
</style>
